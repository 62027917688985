import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardFooter,
  Container,
} from 'reactstrap'
import { FaAudioDescription, FaMendeley } from 'react-icons/fa'
import { useCallback, useEffect, useState } from 'react'
import { getAds } from './../../service/adsService'
import { useNavigate } from 'react-router-dom'
import useUser from './../../hooks/useUser'
import WalletIcon from './../../assets/wallet.png'
import './index.scss'
import Logo from './../../assets/logo.png'
import useBlog from '../../hooks/useBlog'

const TitleCard = ({ title }) => {
  return (
    <div
      style={{
        border: '1px solid #3D4AC9',
        borderRadius: '3px',
        paddingTop: '8px',
        marginBottom: '8px',
        width: '280px',
        background:
          'linear-gradient(90deg, rgba(62, 74, 201, 0.2) 0%, rgba(175, 70, 182, 0.2) 34.17%, rgba(93, 200, 213, 0.2) 69.06%, rgba(62, 74, 201, 0.2) 100%)',
      }}
    >
      <h5
        style={{
          background: 'rgba(71, 180, 214, 0.2)',
          padding: '3px 20px',
          textAlign: 'center',
        }}
      >
        {title}
      </h5>
    </div>
  )
}

export default function Dashboard() {
  const { state: userState, getHomePageData } = useUser()
  const navigate = useNavigate()
  const [adsLoading, setAdsLoading] = useState(false)

  const { state: blogState, fetchBlog } = useBlog()

  const viewAds = useCallback(async () => {
    setAdsLoading(true)
    const response = await getAds()
    if (response.status === 'success') {
      window.location = response.shortenedUrl
    }
    setAdsLoading(false)
  }, [navigate])

  useEffect(() => {
    async function fetchData() {
      await getHomePageData()
    }
    fetchData()
  }, [])

  useEffect(() => {
    fetchBlog()
  }, [])

  const { purchase } = userState.user

  if (userState.loading)
    return (
      <div className="p-4">
        <h1 className="text-white">Loading...</h1>
      </div>
    )

  return (
    <div
      style={{
        color: '#fff',
      }}
    >
      <h5
        style={{
          textAlign: 'center',
        }}
      >
        Current Amount
      </h5>
      <div
        style={{
          background: 'rgba(62, 74, 201, 0.2)',
          textAlign: 'center',
          padding: '30px',
          margin: '30px 0px',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        {/* <h1>{userState?.user?.wallet} USDT</h1> */}
        <TitleCard title={`My Token - ${userState?.user?.myToken}`} />
        <TitleCard title={`Wallet - ${userState?.user?.wallet.toLocaleString()} USDT`} />
        <TitleCard title={`Hold Token - ${userState?.user?.holdToken}`} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <h5
          style={{
            background: 'rgba(62, 74, 201, 0.2)',
            padding: '20px 50px',
            borderLeft: '2px solid #3E4AC9',
            borderRight: '2px solid #3E4AC9',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Total Member: {userState?.user?.directMembers}
        </h5>
        <h5
          style={{
            background: 'rgba(62, 74, 201, 0.2)',
            padding: '20px 50px',
            borderTop: '2px solid #3E4AC9',
          }}
        >
          Current Plan:{' '}
          <img
            src={userState?.user?.purchase?.plan?.logo}
            alt="Plan"
            width="50px"
            height="50px"
            style={{
              borderRadius: '10px',
            }}
          />
        </h5>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '30px',
        }}
      >
        {blogState.blogs.map((blog) => {
          return (
            <div
              key={blog.id}
              style={{
                width: '400px',
                height: '680px',
                margin: '8px',
                background: 'rgba(15, 16, 40, 0.8)',
                border: '1px solid #67C9FF',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '20px',
                flexDirection: 'column',
              }}
            >
              <h5>{blog.title}</h5>

              <img
                src={blog.image}
                alt="Image"
                style={{
                  width: '300px',
                  height: '160px',
                  border: '1px solid #FFFFFF',
                  borderRadius: '10px',
                  margin: '30px 0px',
                }}
              />
              <p>{blog.body}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
