import React, { useEffect, useState, useMemo } from 'react'
import usePlan from '../../hooks/usePlan'
import useUser from '../../hooks/useUser'
import { dateDiff } from '../../utils/dateService'
import './index.scss'
import PlanDetail from './PlanDetail'
function Plans() {
  const [mounted, setMounted] = useState(false)
  const { fetchPlan, state: planState } = usePlan()
  const { state: userState } = useUser()

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchPlan()
    }
  }, [mounted, fetchPlan])

  const { purchase } = userState.user

  const availableDays = useMemo(() => {
    if (!purchase) return
    const diffDays = dateDiff(new Date(purchase.createdAt), new Date())
    return purchase.plan.duration - diffDays
  }, [purchase])


  return (
    <>
      <div className="py-5 d-flex justify-content-center">
        {!purchase ? (
          <h4 style={{ color: 'red' }}>You have no active plan</h4>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <h3 className="text-white">
              Your current plans :{' '}
              <img
                src={purchase?.plan?.logo}
                alt="Plan"
                width="50px"
                height="50px"
                style={{
                  borderRadius: '10px',
                }}
              />
            </h3>
            <h5 className="text-white">Available Day : {availableDays}</h5>
          </div>
        )}
      </div>
      <div className="d-flex flex-wrap plan-card-container justify-content-center">
        {planState.plans.map((plan, index) => (
          <PlanDetail key={`plan-detail-${index + 1}`} data={plan} />
        ))}
      </div>
    </>
  )
}
export default Plans
