import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { Card, CardBody, CardFooter, Button } from 'reactstrap'
import useUser from '../../hooks/useUser'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import usePurchase from '../../hooks/usePurchase'
import useWithdraw from '../../hooks/useWithdraw'

const PlanLabelCard = ({ label, value }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        paddingLeft: '20px',
        paddingRight: '20px',
      }}
    >
      <p>{label}</p>
      <span className="label-btn">{value}</span>
    </div>
  )
}

function PlanDetail({ data }) {
  const [showConfirm, setShowConfirm] = useState(false)
  const [mounted, setMounted] = useState(false)

  const { state: userState, fetchUserProfile } = useUser()
  const { storePurchase } = usePurchase()
  const { state: withdrawState, fetchWithdraw } = useWithdraw()

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchWithdraw()
    }
  }, [mounted, fetchWithdraw])

  const handlePurchase = () => {
    let pendingTotal = 0

    withdrawState.withdraws.forEach((withdraw) => {
      if (withdraw.status === 0) {
        pendingTotal += withdraw.amount
      }
    })

    let amount = data.price - data.price * (data.bonus / 100)
    amount += pendingTotal

    if (userState?.user?.wallet < amount) {
      NotificationManager.warning("You don't have enough wallet!")
    } else {
      setShowConfirm(true)
    }
  }

  const purchaseProcess = async () => {
    const amount = data.price - data.price * (data.bonus / 100)

    await storePurchase({
      amount,
      planId: data.id,
    })
    await fetchUserProfile()
    window.location.reload()
  }

  return (
    <>
      <div
        style={{
          margin: '8px',
        }}
      >
        <div className="cardHeader">
          <h6 style={{ color: `${data?.color}` }}>{data?.name}</h6>
        </div>
        <div
          style={{
            width: '400px',
            height: '550px',
            background: '#152540',
            boxShadow: '0px 10px 60px rgba(142, 121, 62, 0.1)',
            backdropFilter: 'blur(17px)',
            borderRadius: '15px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
          }}
        >
          <div
            style={{
              width: '98%',
              height: '98%',
              border: `4px solid ${data?.color}`,
              borderRadius: '18px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'baseline',
                marginTop: '3px',
              }}
            >
              <h4
                style={{
                  marginRight: '3px',
                }}
              >
                {data?.price}
              </h4>
              <p>
                USDT ({data?.duration ? `${data?.duration} Days` : 'Permanent'})
              </p>
            </div>
            <PlanLabelCard
              label="Activity Fee"
              value={`${data?.activityIncome}%`}
            />
            <PlanLabelCard label="Level" value={data?.maxLevel} />
            <PlanLabelCard label="Min Refer" value={data?.referCount} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'baseline',
                marginTop: '18px',
              }}
            >
              <p>Activity Fee</p>
            </div>
            {data?.plan_settings?.map((plan) => (
              <PlanLabelCard
                key={plan.id}
                label={`Level ${plan.level}`}
                value={`${plan.activityFee}%`}
              />
            ))}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '30px',
              }}
            >
              <Button
                onClick={handlePurchase}
                style={{
                  border: `2px solid ${data?.color}`,
                  borderRadius: '6px',
                  background: 'rgba(15, 16, 40, 0.4)',
                }}
              >
                Purchase Now
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        modalOpen={showConfirm}
        title={`Confirm Purchase`}
        body={`Are you sure to buy (${data.name})?`}
        onToggle={() => setShowConfirm(false)}
        onConfirm={async () => {
          setShowConfirm(false)
          const { purchase } = userState.user

          if (purchase) {
            if (data.price > purchase.plan?.price) {
              purchaseProcess()
            } else {
              NotificationManager.warning('Please buy upper plan!')
            }
          } else {
            purchaseProcess()
          }
        }}
      />
    </>
  )
}

export default PlanDetail
