import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classnames from 'classnames'
import {
  Button,
  Card,
  CardBody,
  Container,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  CardHeader,
  Row,
  Col,
} from 'reactstrap'
import WalletIcon from './../../assets/wallet.png'
import './index.scss'
import useDeposit from '../../hooks/useDeposit'

import dayjs from 'dayjs'
import useUser from '../../hooks/useUser'
import useWithdraw from '../../hooks/useWithdraw'
import useTransfer from '../../hooks/useTransfer'

const btn = {
  border: '2px solid #67C9FF',
  borderRadius: '6px',
  background: 'rgba(15, 16, 40, 0.4)',
}

function Wallet() {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('1')
  const [mounted, setMounted] = useState(false)
  const [modal, setModal] = useState(false)
  const [image, setImage] = useState('')

  const { state, fetchDeposit } = useDeposit()
  const { state: withdrawState, fetchWithdraw } = useWithdraw()
  const { state: transferState, fetchTransfer } = useTransfer()

  const { state: userState } = useUser()

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchDeposit()
      //   fetchWithdraw()
      fetchTransfer()
    }
  }, [mounted, fetchDeposit, fetchTransfer])

  const handleShow = (url) => {
    setModal(true)
    setImage(url)
  }

  const handleShowWithdraw = () => {
    const today = dayjs(Date.now()).format('DD/MM/YYYY')
    const result = withdrawState.withdraws.find(
      (withdraw) => dayjs(withdraw.createdAt).format('DD/MM/YYYY') === today
    )

    if (!result) {
      return true
    }

    return false
  }

  return (
    <Container>
      <Card
        style={{
          padding: '15px',
          borderRadius: '10px',
          background:
            'linear-gradient(90deg, rgba(62, 74, 201, 0.2) 0%, rgba(175, 70, 182, 0.2) 34.17%, rgba(93, 200, 213, 0.2) 69.06%, rgba(62, 74, 201, 0.2) 100%)',
        }}
      >
        <CardHeader>
          <div
            style={{
              color: '#fff',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <h4>Wallet</h4>
              <div style={{ marginTop: '20px' }}>
                <Button
                  color="success"
                  onClick={() => navigate('/deposit')}
                  style={btn}
                >
                  Deposit
                </Button>
                {/* {handleShowWithdraw() && (
                  <Button
                    color="success"
                    onClick={() => navigate('/withdraw')}
                    style={{ marginLeft: '20px', ...btn }}
                  >
                    Withdraw
                  </Button>
                )} */}
                <Button
                  color="success"
                  onClick={() => navigate('/transfer')}
                  style={{ marginLeft: '20px', ...btn }}
                >
                  Transfer
                </Button>
                <Button
                  color="success"
                  onClick={() => navigate('/convert')}
                  style={{ marginLeft: '20px', ...btn }}
                >
                  Convert
                </Button>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody style={{ borderRadius: '0px' }}>
          <Nav
            tabs
            style={{
              borderBottomColor: '#352D5C',
            }}
          >
            <NavItem>
              <NavLink
                className={classnames(
                  { 'active-tab': activeTab === '1' },
                  'tab'
                )}
                onClick={() => {
                  setActiveTab('1')
                }}
              >
                Deposit
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames(
                  { 'active-tab': activeTab === '2' },
                  'tab'
                )}
                onClick={() => {
                  setActiveTab('2')
                }}
              >
                Withdraw
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames(
                  { 'active-tab': activeTab === '3' },
                  'tab'
                )}
                onClick={() => {
                  setActiveTab('3')
                }}
              >
                Transfer
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Table className="mt-3 table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Transcation ID</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {state.deposits.map((deposit, index) => (
                    <tr key={deposit.id}>
                      <td>{index + 1}</td>
                      <td>{deposit.id}</td>

                      <td>{deposit.amount} USDT</td>
                      <td>{dayjs(deposit.createdAt).format('DD/MM/YYYY')}</td>
                      <td>
                        <span
                          className={deposit.status ? 'success' : 'pending'}
                        >
                          {deposit.status ? 'Success' : 'Pending'}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TabPane>
            <TabPane tabId="2">
              <Table className="mt-3 table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Transcation ID</th>
                    <th>Amount</th>
                    <th>Withdraw Fee</th>
                    <th>Total</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {withdrawState.withdraws.map((withdraw, index) => (
                    <tr key={withdraw.id}>
                      <td>{index + 1}</td>
                      <td>{withdraw.id}</td>

                      <td>{withdraw.amount} USDT</td>
                      <td>{withdraw.withdrawFee} USDT</td>
                      <td>{withdraw.total} USDT</td>
                      <td>{dayjs(withdraw.createdAt).format('DD/MM/YYYY')}</td>
                      <td>
                        <span
                          className={
                            withdraw.status === 1
                              ? 'success'
                              : withdraw.status === 2
                                ? 'cancel'
                                : 'pending'
                          }
                          onClick={
                            withdraw.status === 1
                              ? () => handleShow(withdraw.image)
                              : null
                          }
                        >
                          {withdraw.status === 1
                            ? 'Success'
                            : withdraw.status === 2
                              ? 'Cancel'
                              : 'Pending'}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TabPane>
            <TabPane tabId="3">
              <Table className="mt-3 table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>From Member</th>
                    <th>To Member</th>
                    <th>Amount</th>
                    <th>Transfer Fee</th>
                    <th>Total</th>
                    <th>Note</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {transferState.transfers.map((transfer, index) => (
                    <tr key={transfer.id}>
                      <td>{index + 1}</td>
                      <td>{transfer.fromMember?.name}</td>
                      <td>{transfer.toMember?.name}</td>
                      <td>{transfer.amount}</td>
                      <td>{transfer.transferFee}</td>
                      <td>{transfer.total}</td>
                      <td>{transfer.note}</td>
                      <td>{dayjs(transfer.createdAt).format('DD/MM/YYYY')}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
      <Modal
        className="modal-dialog-centered"
        isOpen={modal}
        toggle={() => setModal(!modal)}
        style={{ width: '500px', height: '500px' }}
      >
        <img src={image} alt="Receipt" />
      </Modal>
    </Container>
  )
}

export default Wallet
