import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Form,
  FormGroup,
} from 'reactstrap'
import usePaymentMethod from '../../hooks/usePaymentMethod'
import TextBox from '../../components/Inputs/TextBox'
import SelectBox from '../../components/SelectBox'
import useWithdrawAllowance from '../../hooks/useWithdrawAllowance'
import useWithdraw from '../../hooks/useWithdraw'
import useUser from '../../hooks/useUser'
import { NotificationManager } from 'react-notifications'
import { getUserProfile } from '../../service/authService'
import { useLocation, useNavigate } from 'react-router-dom'

const img = {
  width: 150,
  height: 150,
  border: '1px solid #67C9FF',
  borderRadius: '10px',
}

function Withdraw() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm()

  const [mounted, setMounted] = useState(false)
  const [amount, setAmount] = useState(null)
  const [actionLoading, setActionLoading] = useState(false)
  const [paymentType, setPaymentType] = useState(null)
  const router = useNavigate()

  const { state: paymentState, fetchPaymentMethod } = usePaymentMethod()
  const {
    state: withdrawAllowanceState,
    fetchWithdrawAllowance,
  } = useWithdrawAllowance()

  const options = withdrawAllowanceState.withdrawAllowances.map((el) => {
    return {
      value: el.amount,
      label: el.amount,
    }
  })

  const { storeWithdraw } = useWithdraw()

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchPaymentMethod()
      fetchWithdrawAllowance()
    }
  }, [mounted, fetchPaymentMethod, fetchWithdrawAllowance])

  const submitData = async (values) => {
    setActionLoading(true)
    const user = await getUserProfile()
    if (user.wallet < values.total) {
      NotificationManager.warning("You don't have enough total!")
    } else {
      //  await storeWithdraw(values)
      setActionLoading(false)
      reset()
      router('/wallet')
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(submitData)}>
        <Card
          style={{
            border: '3px solid #2A2B57',
            marginTop: '8px',
            borderRadius: '15px',
            padding: '10px',
            background:
              'linear-gradient(90deg, rgba(62, 74, 201, 0.2) 0%, rgba(175, 70, 182, 0.2) 34.17%, rgba(93, 200, 213, 0.2) 69.06%, rgba(62, 74, 201, 0.2) 100%)',
          }}
        >
          <CardHeader>
            <h4 className="text-white">Make Withdraw Request</h4>
          </CardHeader>
          <CardBody>
            <FormGroup>
              <label className="mb-2 text-white">Amount</label>
              <SelectBox
                control={control}
                className="form-control"
                name="amount"
                rules={{ required: true }}
                label="Amount"
                options={options}
                value={amount}
                onValueChange={(value) => {
                  setAmount(value)
                  let withdrawFee = value * 0.05
                  setValue('withdrawFee', withdrawFee)
                  let total = value + withdrawFee
                  setValue('total', total)
                }}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2 text-white">Payment Type</label>
              <SelectBox
                control={control}
                className="form-control"
                name="paymentType"
                rules={{ required: true }}
                label="Payment Type"
                options={[
                  { value: 'Address', label: 'Address' },
                  { value: 'Binance', label: 'Binance' },
                ]}
                value={paymentType}
                onValueChange={(value) => {
                  setPaymentType(value)
                }}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2 text-white">Note</label>
              <TextBox
                type="text"
                registerProps={register('note', {
                  required: 'Note is required',
                })}
                errors={errors.note}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2 text-white">Withdraw Fee</label>
              <TextBox
                type="number"
                registerProps={register('withdrawFee', {
                  required: 'Withdraw Fee is required',
                })}
                errors={errors.withdrawFee}
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2 text-white">Total</label>
              <TextBox
                type="number"
                registerProps={register('total', {
                  required: 'Total is required',
                })}
                errors={errors.total}
                disabled={true}
              />
            </FormGroup>
            <FormGroup className="mt-2">
              <div className="d-flex justify-content-center py-4">
                <label className="mb-2 text-white">Payment Method</label>
              </div>
              {paymentState.paymentMethods.map((payment, index) => (
                <div
                  className="d-flex justify-content-between mb-3"
                  key={index}
                  style={{ color: '#67C9FF' }}
                >
                  <div style={{ textAlign: 'center' }}>
                    <h6>QR Code</h6>
                    <img style={img} src={payment.QRCode} alt="QR Code" />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <h6>Email ({payment.email})</h6>
                    <img
                      style={img}
                      src="https://www.logo.wine/a/logo/Binance/Binance-Vertical2-Dark-Background-Logo.wine.svg"
                      alt="Email"
                    />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <h6>Address ({payment.address})</h6>
                    <img
                      style={img}
                      src="https://www.logo.wine/a/logo/Binance/Binance-Vertical2-Dark-Background-Logo.wine.svg"
                      alt="Address"
                    />
                  </div>
                </div>
              ))}
            </FormGroup>
          </CardBody>
          <CardFooter className="py-4 d-flex justify-content-end">
            <Button
              size="lg"
              color="success"
              className="button"
              disabled={actionLoading}
            >
              Submit
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Container>
  )
}

export default Withdraw
