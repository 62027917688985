import React, { useState } from 'react'
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from 'reactstrap'
import './navbar.scss'
import { useLocation } from 'react-router-dom'
import useUser from '../../hooks/useUser'
import Logo from './../../assets/logo.png'
import {
  VscThreeBars,
  VscAccount,
  VscLock,
  VscShield,
  VscTriangleDown,
} from 'react-icons/vsc'
import dayjs from 'dayjs'

const NavBar = () => {
  //for update UI
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen(!dropdownOpen)

  const [dropdownOpenWallet, setDropdownOpenWallet] = useState(false)

  const toggleWallet = () => setDropdownOpenWallet(!dropdownOpenWallet)

  // const [isOpen, setIsOpen] = useState(false)
  // const toggle = () => {
  //   setIsOpen(!isOpen)
  // }
  // const routes = [
  //   {
  //     name: 'Home',
  //     link: '/',
  //   },

  //   {
  //     name: 'Plans',
  //     link: '/plans',
  //   },
  //   {
  //     name: 'Wallet',
  //     link: '/wallet',
  //   },
  //   {
  //     name: 'Clickers',
  //     link: '/member',
  //   },
  //   {
  //     name: 'Change Password',
  //     link: '/change-password',
  //   },
  // ]
  const location = useLocation()
  const { state: userState, logout } = useUser()
  const { purchase } = userState.user

  return (
    <Navbar>
      <NavbarBrand>
        <img
          alt="logo"
          src={Logo}
          style={{
            height: 78,
            width: 78,
          }}
        />
      </NavbarBrand>
      <Nav>
        <NavItem>
          <NavLink className="updateNavLink" href="/">
            Home
          </NavLink>
        </NavItem>
        <Dropdown
          nav
          isOpen={dropdownOpenWallet}
          toggle={toggleWallet}
          style={{
            marginRight: '30px',
          }}
        >
          <DropdownToggle
            nav
            style={{
              color: '#fff',
            }}
          >
            Wallet
          </DropdownToggle>
          <DropdownMenu
            style={{
              background: '#111A32',
            }}
          >
            <DropdownItem className="updateDropdownLink" href="/wallet">
              History
            </DropdownItem>
            <DropdownItem divider className="updateDropdownLink" />
            <DropdownItem className="updateDropdownLink" href="/deposit">
              Deposit
            </DropdownItem>
            <DropdownItem divider className="updateDropdownLink" />
            {/* <DropdownItem className="updateDropdownLink" href="/withdraw">
              Withdraw
            </DropdownItem>
            <DropdownItem divider className="updateDropdownLink" /> */}
            <DropdownItem className="updateDropdownLink" href="/transfer">
              Transfer
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <NavItem>
          <NavLink className="updateNavLink" href="/plans">
            Plan
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="updateNavLink" href="/member">
            Members
          </NavLink>
        </NavItem>
        <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            nav
            style={{
              color: '#fff',
            }}
          >
            <VscAccount /> 04{' '}
            <VscTriangleDown
              style={{
                marginLeft: '13px',
              }}
            />
          </DropdownToggle>
          <DropdownMenu
            style={{
              background: '#111A32',
            }}
          >
            <DropdownItem
              className="updateDropdownLink"
              href="/change-password"
            >
              <VscShield />
              Change Password
            </DropdownItem>
            <DropdownItem divider className="updateDropdownLink" />
            <DropdownItem
              className="updateDropdownLink"
              onClick={() => {
                logout()
              }}
            >
              <VscLock /> Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Nav>
    </Navbar>
    // <div className="custom-nav">
    //   <Navbar className="px-5 nav">
    //     <div className="d-flex">
    //       <div className="d-flex justify-content-center align-items-center me-3">
    //         <VscThreeBars
    //           onClick={toggle}
    //           className="fs-5"
    //           style={{ cursor: 'pointer' }}
    //         />
    //       </div>
    //       <NavbarBrand
    //         href="/"
    //         className="d-flex justify-content-center align-items-center"
    //       >
    //         <img
    //           alt="logo"
    //           src={Logo}
    //           style={{
    //             height: 78,
    //             width: 78,
    //           }}
    //         />
    //         <div className="logo">
    //           <h5>Lifepoint Ads</h5>
    //           <span>Northen America</span>
    //         </div>
    //       </NavbarBrand>
    //     </div>
    //     <div className="d-flex align-items-center ">
    //       <div className="d-flex">
    //         <div className="admin-profile align-items-end">
    //           <h5>
    //             {userState?.user?.name}{' '}
    //             {purchase && (
    //               <span>
    //                 ({purchase.plan.name} until{' '}
    //                 {dayjs(
    //                   new Date(purchase.createdAt).setDate(
    //                     new Date(purchase.createdAt).getDate() +
    //                       purchase.plan.duration
    //                   )
    //                 ).format('DD/MM/YYYY')}
    //                 ){' '}
    //               </span>
    //             )}
    //           </h5>
    //           <span>{userState?.user?.wallet} USDT</span>
    //         </div>
    //         <div className="admin-logo">
    //           <span>{userState?.user?.code}</span>
    //         </div>
    //       </div>
    //     </div>
    //   </Navbar>
    //   <div>
    //     <Offcanvas
    //       scrollable
    //       toggle={toggle}
    //       isOpen={isOpen}
    //       style={{ minWidth: '250px', maxWidth: '330px' }}
    //     >
    //       <OffcanvasHeader toggle={toggle}></OffcanvasHeader>
    //       <OffcanvasBody>
    //         <Navbar className="px-2 py-3">
    //           <Nav className="flex-column" navbar>
    //             {routes.map((route, index) => (
    //               <NavItem
    //                 className={`px-2 mb-3 ${
    //                   route.link === location.pathname ? 'active' : ''
    //                 }`}
    //                 key={index}
    //               >
    //                 <NavLink style={{ cursor: 'pointer' }} href={route.link}>
    //                   {route.name}
    //                 </NavLink>
    //               </NavItem>
    //             ))}
    //             <NavLink
    //               onClick={() => {
    //                 logout()
    //               }}
    //               className="px-2 mb-3"
    //             >
    //               Logout
    //             </NavLink>
    //           </Nav>
    //         </Navbar>
    //       </OffcanvasBody>
    //     </Offcanvas>
    //   </div>
    // </div>
  )
}
export default NavBar
